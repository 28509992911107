import { observable, action, makeObservable } from "mobx"
import { api, unauthorizedApi } from "../apis/api"

class CarStore {
  cars = []
  carsOptions = []

  constructor() {
    makeObservable(this, {
      cars: observable,
      carsOptions: observable,
      getCars: action,
      createCar: action,
    })
  }

  convertToOptions = (cars) => {
    const carsOptions = []
    cars.forEach((car) => {
      carsOptions.push({
        value: car.id,
        name: `${car.name} - ${car.license_plate}`,
      })
    })

    this.carsOptions = carsOptions
  }

  getCars = async (force = false) => {
    // Don't call cars if they already exist.
    if (!force && this.cars?.length !== 0) return
    api
      .get("cars")
      .then((response) => {
        this.cars = response.data
        this.convertToOptions(response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  deleteCar = (id) => {
    api
      .delete(`cars/${id}`)
      .then((response) => {
        this.getCars(true)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  createCar = (carForm) => {
    api
      .post("cars", carForm)
      .then((response) => {
        this.getCars(true)
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

const carStore = new CarStore()
export default carStore
