import {
  Col,
  Popconfirm,
  Popover,
  Row,
  Skeleton,
  Divider,
  Button,
  Space,
  Dropdown,
} from "antd"
import React from "react"
import carStore from "../stores/carStore"
import moment from "moment"
import { addAlpha, danger } from "../colors"
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  MoreOutlined,
} from "@ant-design/icons"
import TableWithLoader from "antd-table-loader"
import { observer } from "mobx-react"
import CarFormModel from "../components/CarFormModel"

class CarPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isCarCreationModalOpen: false,
    }
  }

  componentDidMount() {
    carStore.getCars()
  }

  columns = [
    {
      title: "Ονομασία",
      key: "name",
      align: "center",
      filterSearch: true,
      filterMode: "tree",
      dataIndex: "name",
      sorter: (a, b) => (a.name > b.name ? 1 : -1),
    },
    {
      title: "Χρονολογία",
      dataIndex: "year",
      key: "year",
      align: "center",
    },
    {
      title: "Πινακίδα",
      dataIndex: "license_plate",
      key: "license_plate",
      align: "center",
      filterSearch: true,
      filterMode: "tree",
    },
    {
      title: "Κατηγορία",
      dataIndex: "type",
      key: "type",
      align: "center",
      filterSearch: true,
      filterMode: "tree",
      sorter: (a, b) => (a.read_count > b.read_count ? 1 : -1),
    },
    {
      title: <>Ενέργειες</>,
      align: "center",
      render: (record) => (
        <>
          <Dropdown
            trigger={["click"]}
            menu={{
              items: [
                {
                  danger: true,
                  label: "Διαγραφή",
                  key: 1,
                  icon: <DeleteOutlined />,
                },
              ],
              onClick: (event) => {
                event.domEvent.stopPropagation()

                switch (event.key) {
                  case "1":
                    carStore.deleteCar(record.id)
                    break
                  default:
                    break
                }
              },
            }}
          >
            <Button
              type={"text"}
              onClick={(e) => e.stopPropagation()}
              icon={<MoreOutlined style={{ fontSize: "24px" }} />}
            ></Button>
          </Dropdown>
        </>
      ),
    },
  ]

  render() {
    return (
      <>
        <CarFormModel
          onClose={() => this.setState({ isCarCreationModalOpen: false })}
          createCar={(form) => carStore.createCar(form)}
          open={this.state.isCarCreationModalOpen}
        />
        <Divider>Αυτοκίνητα</Divider>
        <Button
          style={{ marginLeft: "5%" }}
          onClick={() => this.setState({ isCarCreationModalOpen: true })}
        >
          Προσθήκη αυτοκινήτου +
        </Button>
        <Row style={{ marginTop: "5%" }}>
          <Col span={1} />
          <Col span={22}>
            <TableWithLoader
              loader={{
                rows: 4,
                component: <Skeleton active loading title={false} />,
              }}
              size={"small"}
              bordered={true}
              dataSource={carStore.cars}
              columns={this.columns}
              rowKey={(record) => record.session_id}
              pagination={{
                pageSize: 10,
                position: ["bottomCenter"],
              }}
            />
          </Col>
          <Col span={1} />
        </Row>
      </>
    )
  }
}

export default observer(CarPage)
