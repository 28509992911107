import moment from "moment"
import { DATE_FORMAT, DATE_TIME_FORMAT } from "../config"
import { isScreenLGAndBigger } from "./utils"

export const getFirstDateAndLastDateOnTheCalendarPanel = (date = moment()) => {
  /**
   * This function returns for the date object's month the first and the last date in a calendar view.
   *
   * Args:
   *    date: Moment object
   * Returns:
   *    firstDate: the first date of the current month in calendar view
   *    lastDate: the last date of the current month in calendar view
   */
  const ROWS = 6
  const DAYS_OF_WEEK = 7

  const firstDate = moment(date).startOf("month")
  const lastDate = moment(date).endOf("month")

  const firstDateDay = firstDate.day()
  firstDate.subtract(firstDateDay, "days")
  lastDate.add(
    ROWS * DAYS_OF_WEEK - Number(lastDate.format("DD")) - firstDateDay,
    "days"
  )

  return {
    firstDate: firstDate.format(DATE_FORMAT),
    lastDate: lastDate.format(DATE_FORMAT),
  }
}

export const isItAfterToday = (date) => {
  const today = moment()

  return date.diff(today) > 0
}

export const dateHasPassed = (date) => {
  return moment().isAfter(moment(date, DATE_TIME_FORMAT))
}

export const isDateAfterDate = (date1, date2) => {
  return date1.diff(date2) > 0
}

export const getWeekDaysNameByScreenSize = (forceSmall = false) => {
  const big = [
    "Κυριακή",
    "Δευτέρα",
    "Τρίτη",
    "Τετάρτη",
    "Πέμπτη",
    "Παρασκευή",
    "Σάββατο",
  ]

  const small = ["Κυ", "Δε", "Τρ", "Τε", "Πε", "Πα", "Σα"]

  if (forceSmall) return small

  return isScreenLGAndBigger() ? big : small
}

export const isToday = (date) =>
  moment().isSame(moment(date, DATE_TIME_FORMAT), "day")

export const getMillis = (date) => {
  return moment(date).format("x")
}

export const isDateInsideRange = (rangeList, date) => {
  const rangeStart = moment(rangeList[0], DATE_TIME_FORMAT).startOf("day")
  const rangeEnd = moment(rangeList[1], DATE_TIME_FORMAT).endOf("day")
  const targetDate = moment(date).startOf("day")

  return (
    targetDate.isSameOrAfter(rangeStart) && targetDate.isSameOrBefore(rangeEnd)
  )
}
