import { Skeleton } from "antd"
import React from "react"

class CalendarEventLoader extends React.Component {
  render() {
    return (
      <Skeleton
        loading
        active
        title={false}
        style={{ margin: "50px", width: "70%" }}
      />
    )
  }
}

export default CalendarEventLoader
