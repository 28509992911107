import {
  Col,
  Popconfirm,
  Popover,
  Row,
  Skeleton,
  Divider,
  Button,
  Space,
  Form,
  Input,
  Select,
  Checkbox,
  DatePicker,
  InputNumber,
} from "antd"
import React from "react"
import moment from "moment"
import { addAlpha, danger } from "../../colors"
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons"
import { observer } from "mobx-react"
import tankLevelStore from "../../stores/tankLevelStore"
import carStore from "../../stores/carStore"
import { contactForm, InputType } from "./contractForm"
import { DATE_TIME_FORMAT } from "../../config"
import Loader from "../../components/Loader"
import contractStore from "../../stores/contractStore"
import Accordion from "../../components/Accordion"

class CreateContractPage extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    this.state = {
      form: {},
      loading: false,
    }
  }

  componentDidUpdate() {
    if (contractStore.contractFormState?.success) {
      this.setState({
        form: {},
        loading: contractStore.contractFormState?.loading,
      })
      contractStore.cleanContractFormState()
      this.formRef.current.resetFields()
    }

    if (contractStore.contractFormState?.success == false) {
      this.setState({
        loading: contractStore.contractFormState?.loading,
      })
      contractStore.cleanContractFormState()
    }
  }

  onSubmitPressed = () => {
    this.formRef.current
      .validateFields()
      .then((values) => {
        this.setState({ loading: true })
        contractStore.createContract(this.state.form)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  componentDidMount() {
    tankLevelStore.getTankLevels()
    carStore.getCars()
  }

  accordionInput = (element) => {
    return (
      <Accordion title={element.name}>
        {this.generateForm(element.children, true)}
      </Accordion>
    )
  }

  optionInput = (element) => {
    return (
      <>
        <Form.Item
          name={element.key}
          label={element.name}
          rules={[
            {
              required: element.required,
            },
          ]}
        >
          <Select
            key={`${element.name}-select`}
            onChange={(value) =>
              this.setState({
                form: {
                  ...this.state.form,
                  [element.key]: value,
                },
              })
            }
            placeholder={element.name}
          >
            {element.options.map((option) => {
              return (
                <Select.Option key={option.value} value={option.value}>
                  {option.name}
                </Select.Option>
              )
            })}
          </Select>
        </Form.Item>
      </>
    )
  }

  checkboxInput = (element) => {
    return (
      <>
        <Form.Item
          name={element.key}
          label={element.name}
          rules={[
            {
              required: element.required,
            },
          ]}
        >
          <Checkbox
            onChange={(e) =>
              this.setState({
                form: {
                  ...this.state.form,
                  [element.key]: e.target.checked,
                },
              })
            }
          >
            {element.name}
          </Checkbox>
        </Form.Item>
      </>
    )
  }

  dateInput = (element) => {
    return (
      <>
        <Form.Item
          name={element.key}
          label={element.name}
          rules={[
            {
              required: element.required,
            },
          ]}
        >
          <DatePicker
            style={{ width: "100%" }}
            format={DATE_TIME_FORMAT}
            showTime={{
              minuteStep: 30,
            }}
            onChange={(date) =>
              this.setState({
                form: {
                  ...this.state.form,
                  [element.key]: date.format(DATE_TIME_FORMAT),
                },
              })
            }
          />
        </Form.Item>
      </>
    )
  }

  plainInput = (element) => {
    return (
      <>
        <Form.Item
          name={element.key}
          label={element.name}
          rules={[
            {
              required: element.required,
            },
          ]}
        >
          <Input
            onChange={(e) => {
              this.setState({
                form: {
                  ...this.state.form,
                  [element.key]: e.target.value,
                },
              })
            }}
            placeholder={element.name}
            disabled={false}
          />
        </Form.Item>
      </>
    )
  }

  emailInput = (element) => {
    return (
      <>
        <Form.Item
          name={element.key}
          label={element.name}
          rules={[
            {
              required: element.required,
              type: "email",
            },
          ]}
        >
          <Input
            onChange={(e) => {
              this.setState({
                form: {
                  ...this.state.form,
                  [element.key]: e.target.value,
                },
              })
            }}
            placeholder={element.name}
            disabled={false}
          />
        </Form.Item>
      </>
    )
  }

  numberInput = (element) => {
    return (
      <>
        <Form.Item
          name={element.key}
          label={element.name}
          rules={[
            {
              required: element.required,
            },
          ]}
        >
          <InputNumber
            style={{ width: "60%" }}
            onChange={(value) => {
              this.setState({
                form: {
                  ...this.state.form,
                  [element.key]: value,
                },
              })
            }}
            min={1}
            placeholder={0}
            disabled={false}
          />
        </Form.Item>
      </>
    )
  }

  generateForm = (extraForm, full = false) => {
    const formElements = extraForm
      ? extraForm
      : contactForm(carStore.carsOptions, tankLevelStore.tankLevelsOptions)

    return formElements.map((element) => {
      let component
      switch (element.type) {
        case InputType.PLAIN:
          component = this.plainInput(element)
          break
        case InputType.NUMBER:
          component = this.numberInput(element)
          break
        case InputType.CHECKBOX:
          component = this.checkboxInput(element)
          break
        case InputType.OPTIONS:
          component = this.optionInput(element)
          break
        case InputType.DATE:
          component = this.dateInput(element)
          break
        case InputType.EMAIL:
          component = this.emailInput(element)
          break
        case InputType.ACCORDION:
          component = this.accordionInput(element)
          break
      }

      return (
        <Col
          key={element.name}
          xs={24}
          md={full ? 24 : 8}
          lg={full ? 24 : 8}
          span={full ? 24 : 8}
        >
          {component}
        </Col>
      )
    })
  }

  render() {
    if (
      carStore.carsOptions.length == 0 ||
      tankLevelStore.tankLevelsOptions.length == 0
    ) {
      return <Loader />
    }

    return (
      <>
        <Divider>Δημιουργία συμβολαίου</Divider>
        <Form ref={this.formRef} layout="vertical">
          <Row style={{ margin: "2%" }} gutter={[20, 20]}>
            {this.generateForm()}

            <Col key={"button"} span={24}>
              <Button
                style={{ marginBottom: "5%" }}
                loading={
                  contractStore.contractFormState?.loading ? true : false
                }
                onClick={() => this.onSubmitPressed()}
              >
                Δημιουργία
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    )
  }
}

export default observer(CreateContractPage)
