import React from "react"
import { primaryColor } from "./colors"
import { ConfigProvider } from "antd"
import el_GR from "antd/locale/el_GR"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import PrivateComponent from "./components/PrivateComponent"
import PageTemplate from "./screens/misc/PageTemplate"
import NotFound from "./screens/misc/NotFound"
import Loader from "./components/Loader"
import userStore from "./stores/userStore"
import LoginPage from "./screens/LoginPage"

import { observer } from "mobx-react"
import CarPage from "./screens/CarPage"
import ContractPage from "./screens/contract/ContractPage"
import CreateContractPage from "./screens/contract/CreateContractPage"
import HomePage from "./screens/HomePage"

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <PrivateComponent>
        <HomePage />
      </PrivateComponent>
    ),
  },
  {
    path: "/cars",
    element: (
      <PrivateComponent>
        <CarPage />
      </PrivateComponent>
    ),
  },
  {
    path: "/contracts",
    element: (
      <PrivateComponent>
        <ContractPage />
      </PrivateComponent>
    ),
  },
  {
    path: "/create-contract",
    element: (
      <PrivateComponent>
        <CreateContractPage />
      </PrivateComponent>
    ),
  },
  {
    path: "*",
    element: <NotFound />,
  },
])

class Root extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {}

  render() {
    // if (this.props.isLoggedIn == null) {
    //     return <Loader absoluteCenter={true} />
    // }

    return (
      <>
        <ConfigProvider
          locale={el_GR}
          theme={{
            token: {
              colorPrimary: primaryColor,
              fontSize: 13,
              lineWidth: 1.4,
              colorText: "black",
              colorInfo: primaryColor,
              lineWidth: 1.2,
            },
            components: {
              Input: {
                colorPrimary: primaryColor,
              },
              Radio: {
                colorPrimary: primaryColor,
              },
              Button: {
                colorPrimary: primaryColor,
              },
              Card: {
                colorPrimary: primaryColor,
              },
              Tabs: {
                colorPrimary: primaryColor,
              },
              Modal: {
                colorPrimary: primaryColor,
              },
              Switch: {
                colorPrimary: primaryColor,
              },
              Table: {
                colorPrimary: primaryColor,
              },
              Menu: {
                // colorBgLayout: primaryColor,
                // darkItemBg: primaryColor,
              },
            },
          }}
        >
          <div
            style={{
              // minHeight: "100vh",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {userStore.token ? this.renderPrivateNavigation() : <LoginPage />}
          </div>
        </ConfigProvider>
      </>
    )
  }

  renderPrivateNavigation = () => {
    return <RouterProvider router={router} />
  }
}

export default observer(Root)
