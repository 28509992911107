import { observable, action, makeObservable } from "mobx"
import { api, unauthorizedApi } from "../apis/api"

class TankLevelStore {
  tankLevels = []
  tankLevelsOptions = []

  constructor() {
    makeObservable(this, {
      tankLevels: observable,
      tankLevelsOptions: observable,
      getTankLevels: action,
    })
  }

  convertToOptions = (tankLevels) => {
    const tankLevelsOptions = []
    tankLevels.forEach((tankLevel) => {
      tankLevelsOptions.push({
        value: tankLevel.id,
        name: tankLevel.tank_level,
      })
    })

    this.tankLevelsOptions = tankLevelsOptions
  }

  getTankLevels = async () => {
    // Don't call tank levels if they already exist.
    if (this.tankLevels?.length !== 0) return

    api
      .get("tank-level")
      .then((response) => {
        this.tankLevels = response.data
        this.convertToOptions(response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

const tankLevelStore = new TankLevelStore()
export default tankLevelStore
