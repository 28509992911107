import React from "react"
import MainMenu from "./MainMenu"

class PrivateComponent extends React.Component {

    render() {
        return <MainMenu>{this.props.children}</MainMenu>
    }
}

export default PrivateComponent
