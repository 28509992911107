export const InputType = {
  PLAIN: "plain",
  NUMBER: "number",
  CHECKBOX: "checkbox",
  OPTIONS: "options",
  DATE: "date",
  EMAIL: "email",
  ACCORDION: "accordion",
}

export const contactForm = (cars, tankLevels) => {
  return [
    // Βασικά στοιχεία πελάτη
    {
      key: "first_name",
      max_length: "256",
      type: InputType.PLAIN,
      name: "Όνομα πελάτη",
      required: true,
    },
    {
      key: "last_name",
      max_length: "256",
      type: InputType.PLAIN,
      name: "Επώνυμο πελάτη",
      required: true,
    },
    {
      key: "phone_number",
      max_length: "256",
      type: InputType.PLAIN,
      name: "Τηλέφωνο επικοινωνίας",
      required: true,
    },
    {
      key: "home_address",
      max_length: "256",
      type: InputType.PLAIN,
      name: "Διεύθυνση μόνιμη",
      required: true,
    },
    {
      key: "local_address",
      max_length: "256",
      type: InputType.PLAIN,
      name: "Διεύθυνση τοπική",
      required: true,
    },
    {
      key: "driving_license",
      max_length: "20",
      type: InputType.PLAIN,
      name: "Άδεια οδηγού (αριθμός)",
      required: true,
    },
    {
      key: "identity_number",
      max_length: "15",
      type: InputType.PLAIN,
      name: "Α.Δ. Ταυτότητας/Διαβατηρίου πελάτη",
      required: true,
    },
    {
      key: "email",
      max_length: "256",
      type: InputType.EMAIL,
      name: "Email πελάτη",
      required: true,
    },
    //  Έξτρα οδηγός
    {
      name: "Στοιχεία έξτρα οδηγού",
      type: InputType.ACCORDION,
      children: [
        {
          key: "first_name_secondary",
          max_length: "256",
          type: InputType.PLAIN,
          name: "Όνομα πελάτη",
          required: true,
        },
        {
          key: "last_name_secondary",
          max_length: "256",
          type: InputType.PLAIN,
          name: "Επώνυμο πελάτη",
          required: true,
        },
        {
          key: "home_address_secondary",
          max_length: "256",
          type: InputType.PLAIN,
          name: "Διεύθυνση μόνιμη",
          required: true,
        },
        {
          key: "local_address_secondary",
          max_length: "256",
          type: InputType.PLAIN,
          name: "Διεύθυνση τοπική",
          required: true,
        },
        {
          key: "driving_license_secondary",
          max_length: "20",
          type: InputType.PLAIN,
          name: "Άδεια οδηγού (αριθμός)",
          required: true,
        },
        {
          key: "identity_number_secondary",
          max_length: "15",
          type: InputType.PLAIN,
          name: "Α.Δ. Ταυτότητας/Διαβατηρίου πελάτη",
          required: true,
        },
      ],
    },
    // Αυτοκίνητο
    {
      key: "car",
      max_length: "256",
      options: cars,
      type: InputType.OPTIONS,
      name: "Αυτοκίνητο",
      required: true,
    },
    {
      key: "tank_level",
      max_length: "256",
      options: tankLevels,
      type: InputType.OPTIONS,
      name: "Επίπεδο ντεπόζιτου",
      required: true,
    },
    {
      key: "has_cdw",
      max_length: "256",
      type: InputType.CHECKBOX,
      name: "Αν έχει μεικτή ασφάλεια",
      required: false,
    },
    // Αντικείμενα που συνοδεύονται με το όχημα
    {
      key: "has_gps",
      max_length: "256",
      type: InputType.CHECKBOX,
      name: "Αν έχει GPS",
      required: false,
    },
    {
      key: "has_child_chair",
      max_length: "256",
      type: InputType.CHECKBOX,
      name: "Αν έχει παιδικό κάθισμα",
      required: false,
    },
    {
      key: "has_radio_system",
      max_length: "256",
      type: InputType.CHECKBOX,
      name: "Αν έχει ραδιόφωνο",
      required: false,
    },
    {
      key: "has_spare_tire",
      max_length: "256",
      type: InputType.CHECKBOX,
      name: "Αν έχει ρεζέρβα",
      required: false,
    },
    {
      key: "has_tools",
      max_length: "256",
      type: InputType.CHECKBOX,
      name: "Αν έχει εργαλεία",
      required: false,
    },
    {
      key: "has_jack",
      max_length: "256",
      type: InputType.CHECKBOX,
      name: "Αν έχει γρύλο",
      required: false,
    },

    // Ημερομηνίες αναχώρησης/επιστροφής
    {
      key: "start_date",
      max_length: "256",
      type: InputType.DATE,
      name: "Ημερ. αναχώρησης",
      required: true,
    },
    {
      key: "end_date",
      max_length: "256",
      type: InputType.DATE,
      name: "Ημερ. επιστροφής",
      required: true,
    },
    {
      key: "rental_days",
      max_length: "256",
      type: InputType.NUMBER,
      name: "Ημέρες ενοικίασης",
      required: true,
    },
    // Διανομή αυτοκινήτου
    {
      key: "start_point",
      max_length: "256",
      type: InputType.PLAIN,
      name: "Σημείο παράδοσης",
      required: true,
    },
    {
      key: "end_point",
      max_length: "256",
      type: InputType.PLAIN,
      name: "Σημείο επιστροφής",
      required: false,
    },

    // Στοιχεία πληρωμής
    {
      key: "price_per_day",
      max_length: "256",
      type: InputType.PLAIN,
      name: "Τιμή ανα ημέρα",
      required: true,
    },
    {
      key: "is_paid",
      max_length: "256",
      type: InputType.CHECKBOX,
      name: "Αν έχει εξωφλήσει ο πελάτης",
      required: false,
    },
    {
      key: "card_payment",
      max_length: "256",
      type: InputType.CHECKBOX,
      name: "Πληρωμή με κάρτα",
      required: false,
    },
    {
      key: "cash_payment",
      max_length: "256",
      type: InputType.CHECKBOX,
      name: "Πληρωμή με μετρητά",
      required: false,
    },
    {
      key: "bank_deposit_payment",
      max_length: "256",
      type: InputType.CHECKBOX,
      name: "Πληρωμή με κατάθεση",
      required: false,
    },
    {
      key: "payment_in_advance",
      max_length: "256",
      type: InputType.PLAIN,
      name: "Προκαταβολή",
      required: false,
    },
    {
      key: "total_cost",
      max_length: "256",
      type: InputType.PLAIN,
      name: "Συνολικό ποσό",
      required: true,
    },
  ]
}
