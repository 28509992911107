const isDevelopment = process.env.NODE_ENV === "development"

export const API_URL = isDevelopment
  ? "http://localhost:8000/api/v1"
  : "process.env.REACT_APP_API_URL"

export const DATE_FORMAT = "DD-MM-YYYY"
export const DATE_TIME_FORMAT = "DD-MM-YYYY HH:mm"

export const PLATFORM_NAME = "Διαχείρηση ενοικιάσεων"
/*
 * **Below import the images that you want to use**
 *
 *
 * import myImageName from "../public/my_image.png"
 * export const MY_IMAGE_NAME = myImageName
 *
 *
 * Writing the above code makes webpack to keep the images in the build folder after the production build
 * If I try to import images dynamicly in runtime.
 * They will not be available, due to webpack cleaning unused files when it creates the bundle
 */
import IkariaKamposCarsLogo from "../public/ikaria_kampos_cars_logo.png"
export const LOGO = IkariaKamposCarsLogo
