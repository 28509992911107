export const primaryColor = "#1290cd"

export const active = "#6a9436"
export const done = "#a9a9a9"
export const upcoming = "#1290cd"

export const addAlpha = (color, opacity) => {
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255)
  return color + _opacity.toString(16).toUpperCase()
}
