import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  Modal,
  Row,
  Space,
  Form,
  Input,
} from "antd"
import moment from "moment"
import React from "react"
import { CarOutlined } from "@ant-design/icons"
import { DATE_FORMAT } from "../config"
import { primaryColor } from "../colors"

class CarFormModel extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)
    this.state = {
      name: "",
      license_plate: "",
      type: "A",
      year: "",
    }
  }

  renderForm() {
    return (
      <Form ref={this.formRef} layout="vertical">
        {/* Ονομασία */}
        <Form.Item
          key={"name"}
          name="name"
          label={"Ονομασία"}
          rules={[
            {
              required: true,
              message: "Παρακαλώ εισάγετε ονομασία.",
            },
          ]}
        >
          <Input
            onChange={(e) => {
              this.setState({ name: e.target.value })
            }}
            className="inputs"
            style={{ height: "8vh" }}
            placeholder={"Fiat Panda"}
            disabled={false}
          />
        </Form.Item>

        <Form.Item
          key={"license_plate"}
          name="license_plate"
          label={"Πινακίδα"}
          rules={[
            {
              required: true,
              message: "Παρακαλώ εισάγετε πινακίδα.",
            },
          ]}
        >
          <Input
            onChange={(e) => {
              this.setState({ license_plate: e.target.value })
            }}
            className="inputs"
            style={{ height: "8vh" }}
            placeholder={"χχχχ0000"}
            disabled={false}
            maxLength={7}
            showCount={true}
          />
        </Form.Item>

        <Form.Item
          key={"year"}
          name="year"
          label={"Χρονολογία"}
          rules={[
            {
              required: true,
              message: "Παρακαλώ εισάγετε χρονολογία.",
            },
          ]}
        >
          <Input
            onChange={(e) => {
              this.setState({ year: e.target.value })
            }}
            className="inputs"
            style={{ height: "8vh" }}
            placeholder={"20χχ"}
            disabled={false}
            maxLength={4}
            showCount={true}
          />
        </Form.Item>

        <Form.Item
          key={"type"}
          name="type"
          label={"Κατηγορία"}
          rules={[
            {
              required: true,
              message: "Παρακαλώ εισάγετε χρονολογία.",
            },
          ]}
        >
          <Input
            onChange={(e) => {
              this.setState({ type: e.target.value })
            }}
            className="inputs"
            style={{ height: "8vh" }}
            placeholder={"π.χ. Α,Β"}
            disabled={false}
            maxLength={1}
            showCount={true}
          />
        </Form.Item>
      </Form>
    )
  }

  isFormOk = () => {
    return (
      this.state.license_plate &&
      this.state.year &&
      this.state.name &&
      this.state.type
    )
  }

  onCreateCar = () => {
    this.formRef.current
      .validateFields()
      .then((values) => {
        this.props.createCar({
          name: this.state.name,
          license_plate: this.state.license_plate,
          year: this.state.year,
          type: this.state.type,
        })
        this.props.onClose()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  render() {
    return (
      <Modal
        destroyOnClose={true}
        title={
          <p style={{ fontSize: "26px", color: primaryColor }}>
            {" "}
            <CarOutlined
              style={{ fontSize: "24px", marginRight: "16px" }}
            />{" "}
            Προσθήκη αυτοκινήτου
          </p>
        }
        open={this.props.open}
        onCancel={() => this.props.onClose(null)}
        onOk={() => this.onCreateCar()}
      >
        {this.renderForm()}
      </Modal>
    )
  }
}

export default CarFormModel
