import React from 'react'
import { createRoot } from 'react-dom/client'
import Root from './Root'
import moment from "moment"
import "moment/min/locales"
import { DATE_FORMAT } from "./config"
import dayjs from "dayjs"
import 'dayjs/locale/el'
moment.locale("el")
moment.defaultFormat = DATE_FORMAT
dayjs.locale('el')

import './App.css';

const App = () => {
    return <Root />
}


const AppWrapper = () => {
    return (
        <App />
    )
}


const domNode = document.getElementById("root")
const root = createRoot(domNode)
root.render(<AppWrapper />)