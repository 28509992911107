import { observable, action, makeObservable } from "mobx"
import { api, unauthorizedApi } from "../apis/api"
import { notification } from "antd"
import { saveAs } from "file-saver"
import moment from "moment"
import { DATE_TIME_FORMAT } from "../config"
import { dateHasPassed, isDateInsideRange } from "../utils/date"
import { lowerCaseAllWordsExceptFirstLetters } from "../utils/utils"
import cloneDeep from "lodash/cloneDeep"

class ContractStore {
  contracts = null
  contractEvents = null
  contractFormState = {}

  constructor() {
    makeObservable(this, {
      contracts: observable,
      contractEvents: observable,
      contractFormState: observable.deep,
      setContractEvents: action,
      getContracts: action,
      createContract: action,
    })
  }

  getRentalStatus = (contract) => {
    let rental_status = ""
    if (dateHasPassed(contract.end_date)) {
      rental_status = "Έχει τελιώσει η ενοικίαση"
    } else if (
      isDateInsideRange([contract.start_date, contract.end_date], new Date())
    ) {
      rental_status = "Η ενοικίαση είναι σε εξέλιξη"
    } else {
      let now = moment()
      let then = moment(contract.start_date, DATE_TIME_FORMAT)
      let duration = moment.duration(then.diff(now))

      let days = Math.floor(duration.asDays())
      let hours = duration.hours()
      let minutes = duration.minutes()

      let dateFromNowText = ""
      if (days > 0) {
        dateFromNowText += `${days} ${days == 1 ? "ημέρα" : "ημέρες"}`
      }
      if (hours > 0) {
        dateFromNowText += ` και ${hours} ${hours == 1 ? "ώρα" : "ώρες"} `
      }

      rental_status = `Η ενοικίαση είναι θα ξεκινήσει σε ${dateFromNowText}`
    }

    return rental_status
  }

  setContractEvents = (contracts) => {
    this.contractEvents = contracts.map((contract) => {
      return {
        ...contract,
        rental_status: this.getRentalStatus(contract),
        title:
          lowerCaseAllWordsExceptFirstLetters(
            `${contract.first_name} ${contract.last_name}`
          ) + ` | ${contract.car.name} ${contract.car.license_plate}`,
      }
    })
  }

  getContractForEdit = (contractId) => {
    const contract = cloneDeep(
      this.contracts?.find(({ id }) => id == contractId)
    )

    contract["start_date"] = moment(contract["start_date"], DATE_TIME_FORMAT)
    contract["end_date"] = moment(contract["end_date"], DATE_TIME_FORMAT)

    contract["car"] = contract["car"]["id"]
    contract["tank_level"] = contract["tank_level"]["id"]

    if (contract.hasOwnProperty("signature")) {
      delete contract["signature"]
    }

    return contract
  }

  cleanContractFormState = () => {
    this.contractFormState = {}
  }

  getContracts = async () => {
    api
      .get("contracts")
      .then((response) => {
        this.contracts = response.data
        this.setContractEvents(response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  deleteContact = (id) => {
    api
      .delete(`contracts/${id}`)
      .then((response) => {
        notification.success({
          message: "Επιτυχία",
          description: "Το συμβόλαιο διαγράφθηκε",
        })
        this.getContracts()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  createSingature = (signature, id) => {
    api
      .post(`contracts/${id}/signature`, {
        signature: signature,
      })
      .then((response) => {
        this.contractFormState = {
          success: true,
          loading: false,
        }
        notification.success({
          message: "Επιτυχία",
          description: "Η υπογραφή αποθηκέυτηκε",
        })
        this.getContracts()
      })
      .catch((error) => {
        console.log(error)
        this.contractFormState = {
          success: false,
          loading: false,
        }
      })
  }

  createContract = (contractForm) => {
    api
      .post("contracts", contractForm)
      .then((response) => {
        this.contractFormState = {
          success: true,
          loading: false,
        }
        notification.success({
          message: "Επιτυχία",
          description: "Το συμβόλαιο δημιουργήθηκε",
        })
        this.getContracts()
      })
      .catch((error) => {
        console.log(error)
        this.contractFormState = {
          success: false,
          loading: false,
        }
      })
  }

  updateContract = (contractForm) => {
    const form = { ...contractForm }
    if (moment.isMoment(contractForm.start_date)) {
      form["start_date"] = contractForm.start_date.format(DATE_TIME_FORMAT)
    }
    if (moment.isMoment(contractForm.end_date)) {
      form["end_date"] = contractForm.end_date.format(DATE_TIME_FORMAT)
    }

    api
      .patch(`contracts/${contractForm.id}`, form)
      .then((response) => {
        this.contractFormState = {
          success: true,
          loading: false,
        }
        notification.success({
          message: "Επιτυχία",
          description: "Το συμβόλαιο ενημερώθηκε",
        })
        this.getContracts()
      })
      .catch((error) => {
        console.log(error)
        this.contractFormState = {
          success: false,
          loading: false,
        }
      })
  }

  getContractPrintout = (id) => {
    api
      .get(`contracts/${id}/printout`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        saveAs(
          new Blob([response.data], { type: "application/pdf" }),
          `Συμβόλαιο-${id}.pdf`
        )
        notification.success({
          message: "Επιτυχία",
          description: "Το συμβόλαιο αποθηκέυτηκε",
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  sendContactToMail = (id) => {
    api
      .post(`contracts/${id}/send-mail`)
      .then((response) => {
        notification.success({
          message: "Επιτυχία",
          description: "Το συμβόλαιο στάλθηκε",
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

const contractStore = new ContractStore()
export default contractStore
