import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  Modal,
  Row,
  Space,
  Form,
  Input,
} from "antd"
import moment from "moment"
import React from "react"
import { CarOutlined } from "@ant-design/icons"
import { DATE_FORMAT } from "../config"
import { primaryColor } from "../colors"
import SignaturePad from "signature_pad"
import contractStore from "../stores/contractStore"

class SignatureModal extends React.Component {
  signaturePad = null
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
    }
  }

  renderPad() {
    return (
      <canvas
        style={{
          border: `3px solid ${primaryColor}`,
          borderRadius: 50,
          width: "99%",
          height: "99%",
        }}
        id={"canvas"}
      />
    )
  }

  componentDidUpdate() {
    if (contractStore.contractFormState?.success) {
      this.setState({
        loading: contractStore.contractFormState?.loading,
      })
      contractStore.cleanContractFormState()
      this.props.close()
    }

    if (contractStore.contractFormState?.success == false) {
      this.setState({
        loading: contractStore.contractFormState?.loading,
      })
      contractStore.cleanContractFormState()
    }
  }

  componentDidMount() {
    const canvas = document.getElementById("canvas")

    const resizeCanvas = () => {
      this.signaturePad = new SignaturePad(canvas)
      const ratio = Math.max(window.devicePixelRatio || 1, 1)
      canvas.width = canvas.offsetWidth * ratio
      canvas.height = canvas.offsetHeight * ratio
      canvas.getContext("2d").scale(ratio, ratio)
      this.signaturePad.clear()
    }

    resizeCanvas()
    window.addEventListener("resize", resizeCanvas)
  }

  onSave = () => {
    if (!this.signaturePad.isEmpty()) {
      const img = this.signaturePad.toDataURL()
      let prefix = "data:image/png;base64,"
      if (img.startsWith(prefix)) {
        this.setState({ loading: true })
        let base64String = img.slice(prefix.length)
        contractStore.createSingature(base64String, this.props.contractId)
      }
    }
  }

  render() {
    return (
      <Modal
        width={"100%"}
        style={{ margin: 0, top: 0, padding: 0 }}
        bodyStyle={{ height: "100vh", margin: 0 }}
        destroyOnClose={true}
        okText={"Αποθήκευση"}
        title={
          <Space>
            <Button onClick={() => this.onSave()}>Αποθήκευση</Button>
            <Button onClick={() => this.signaturePad.clear()}>
              Καθαρισμός
            </Button>
          </Space>
        }
        loading={contractStore.contractFormState?.loading ? true : false}
        open={this.props.open}
        onCancel={() => this.props.close(null)}
        onOk={() => this.onSave()}
      >
        {this.renderPad()}
      </Modal>
    )
  }
}

export default SignatureModal
