import React from "react"


class PageTemplate extends React.Component {
    render() {
        return (
            <p>
                Blank page
            </p>
        )
    }
}

export default PageTemplate