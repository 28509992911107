import {
  Col,
  Popconfirm,
  Popover,
  Row,
  Skeleton,
  Divider,
  Button,
  Space,
  Dropdown,
} from "antd"
import React from "react"
import moment from "moment"
import { addAlpha, danger } from "../../colors"
import {
  PrinterOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  MoreOutlined,
  EditOutlined,
  FormOutlined,
  MailOutlined,
} from "@ant-design/icons"
import TableWithLoader from "antd-table-loader"
import { observer } from "mobx-react"
import contractStore from "../../stores/contractStore"
import SignatureModal from "../../components/SignatureModal"
import { DATE_TIME_FORMAT } from "../../config"
import EditContractModal from "../../components/contract/EditContractModal"

class ContractPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isContactCreationModalOpen: false,
      selectedContactSignatureId: null,
      selectedContractEdit: null,
    }
  }

  componentDidMount() {
    contractStore.getContracts()
  }

  columns = [
    {
      title: <>Πελάτης</>,
      align: "center",
      children: [
        {
          title: "Ονοματεπώνυμο",
          align: "center",
          render: (record) => `${record.first_name} ${record.last_name}`,
          key: "full_name",
        },
        {
          title: "Τηλέφωνο",
          align: "center",
          render: (record) => `${record.phone_number}`,
          key: "phone_number",
        },
        // {
        //   title: "Email",
        //   align: "center",
        //   render: (record) => `${record.email}`,
        //   key: "email",
        //   responsive: ["lg", "md"],
        // },
      ],
    },
    {
      title: <>Αυτοκίνητο</>,
      align: "center",
      responsive: ["lg", "md"],
      children: [
        {
          title: "Ονομασία",
          align: "center",
          render: (record) => `${record.car?.name}`,
          key: "name",
        },
        {
          title: "Πινακίδα",
          align: "center",
          render: (record) => `${record.car?.license_plate}`,
          key: "license_plate",
        },
        // {
        //   title: "Ντεπόζιτον",
        //   align: "center",
        //   render: (record) => `${record.tank_level?.tank_level}`,
        //   key: "tank_level",
        // },
      ],
    },
    {
      title: <>Παράδοση</>,
      align: "center",
      responsive: ["lg", "md"],
      children: [
        {
          title: "Ημερομηνία",
          align: "center",
          render: (record) => `${record.start_date}`,
          key: "start_date",
          sorter: (a, b) => (a.start_date > b.start_date ? 1 : -1),
        },
        {
          title: "Σημείο παράδοσης",
          align: "center",
          render: (record) => `${record.start_point}`,
          key: "start_point",
          sorter: (a, b) => (a.start_point > b.start_point ? 1 : -1),
        },
      ],
    },
    {
      title: <>Υπογεγραμμένο</>,
      align: "center",
      render: (record) => <>{record.is_signed ? "Ναι" : "Όχι"}</>,
    },
    {
      title: <>Πότε ξεκινάει</>,
      align: "center",
      render: (record) => (
        <>{moment(record.start_date, DATE_TIME_FORMAT).fromNow()}</>
      ),
    },
    {
      title: <>Ενέργειες</>,
      align: "center",
      render: (record) => (
        <>
          <Dropdown
            trigger={["click"]}
            menu={{
              items: [
                {
                  label: "Εκτύπωση",
                  key: 1,
                  icon: <PrinterOutlined />,
                },
                {
                  label: "Υπογραφή",
                  key: 2,
                  icon: <EditOutlined />,
                },
                {
                  danger: true,
                  label: "Διαγραφή",
                  key: 3,
                  icon: <DeleteOutlined />,
                },
                {
                  label: "Αποστολή Email",
                  key: 4,
                  icon: <MailOutlined />,
                  disabled: !record.is_signed
                },
                {
                  label: "Επεξεργασία",
                  key: 5,
                  icon: <FormOutlined />,
                },
              ],
              onClick: (event) => {
                event.domEvent.stopPropagation()

                switch (event.key) {
                  case "1":
                    contractStore.getContractPrintout(record.id)
                    break
                  case "2":
                    this.setState({ selectedContactSignatureId: record.id })
                    break
                  case "3":
                    contractStore.deleteContact(record.id)
                    break
                  case "4":
                    contractStore.sendContactToMail(record.id)
                    break
                  case "5":
                    this.setState({ selectedContractEdit: record })

                    break
                  default:
                    break
                }
              },
            }}
          >
            <Button
              type={"text"}
              onClick={(e) => e.stopPropagation()}
              icon={<MoreOutlined style={{ fontSize: "24px" }} />}
            ></Button>
          </Dropdown>
        </>
      ),
    },
  ]

  render() {
    return (
      <>
        {this.state.selectedContactSignatureId ? (
          <SignatureModal
            close={() => this.setState({ selectedContactSignatureId: null })}
            open={this.state.selectedContactSignatureId}
            contractId={this.state.selectedContactSignatureId}
          />
        ) : (
          <></>
        )}

        {this.state.selectedContractEdit ? (
          <EditContractModal
            contractId={this.state.selectedContractEdit.id}
            open={this.state.selectedContractEdit}
            close={() =>
              this.setState({
                selectedContractEdit: null,
              })
            }
          />
        ) : (
          <></>
        )}

        <Divider>Συμβόλαια</Divider>
        <Row style={{ marginTop: "5%" }}>
          <Col span={1} />
          <Col span={22}>
            <TableWithLoader
              loader={{
                rows: 4,
                component: <Skeleton active loading title={false} />,
              }}
              size={"small"}
              bordered={true}
              dataSource={contractStore.contracts}
              columns={this.columns}
              rowKey={(record) => record.session_id}
              pagination={{
                pageSize: 10,
                position: ["bottomCenter"],
              }}
            />
          </Col>
          <Col span={1} />
        </Row>
      </>
    )
  }
}

export default observer(ContractPage)
