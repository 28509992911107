import React from "react"
import { Collapse } from "antd"

class Accordion extends React.Component {
  render() {
    return (
      <Collapse style={{ marginTop: "16px" }}>
        <Collapse.Panel header={this.props.title}>
          {this.props.children}
        </Collapse.Panel>
      </Collapse>
    )
  }
}

export default Accordion
