import React from "react"
import { observer } from "mobx-react"
import { Row, Col, Badge, Popover } from "antd"
import {
  dateHasPassed,
  getFirstDateAndLastDateOnTheCalendarPanel,
  getWeekDaysNameByScreenSize,
  isDateInsideRange,
  isToday,
} from "../utils/date"
import moment from "moment"
import {
  isScreenLGAndBigger,
  lowerCaseAllWordsExceptFirstLetters,
} from "../utils/utils"
import contractStore from "../stores/contractStore"
import CalendarEventLoader from "../components/CalendarEventLoader"
import { active, addAlpha, done, primaryColor, upcoming } from "../colors"
import { Calendar } from "../components/DateAntdComponents"
import { DATE_FORMAT, DATE_TIME_FORMAT } from "../config"
import EditContractModal from "../components/contract/EditContractModal"

class HomePage extends React.Component {
  constructor(props) {
    super(props)
    const initDates = getFirstDateAndLastDateOnTheCalendarPanel()
    this.state = {
      firstDate: initDates.firstDate,
      lastDate: initDates.lastDate,
      panelDate: moment(),

      hoverEventColors: [],
      hoverShowMoreColors: [],
      displayEvent: null,
    }
  }

  componentDidMount() {
    contractStore.getContracts()
  }

  renderEvent = (event) => {
    return (
      <Popover content={event.rental_status} title={event.title}>
        <Row style={{ marginBottom: "8px" }}>
          <Col
            onClick={(e) => {
              e.stopPropagation()
              this.setState({
                displayEvent: event,
              })
            }}
            span={24}
            style={{
              cursor: "pointer",
              background: dateHasPassed(event.end_date)
                ? done
                : isDateInsideRange(
                    [event.start_date, event.end_date],
                    new Date()
                  )
                ? active
                : upcoming,
              borderRadius: 5,
            }}
            onMouseEnter={(e) => e.preventDefault()}
            onMouseLeave={(e) => e.stopPropagation()}
          >
            <span
              style={{
                marginLeft: "2px",
                color: "white",
                fontSize: "8px",
                ...styles.titleWrapper,
              }}
            >
              <Badge
                status={
                  isDateInsideRange(
                    [event.start_date, event.end_date],
                    new Date()
                  )
                    ? "processing"
                    : "default"
                }
                color="white"
                style={{ marginRight: "8px", marginLeft: "2px" }}
              />
              {event.title}
            </span>
          </Col>
        </Row>
      </Popover>
    )
  }

  getEventsOrNot = (date) => {
    return contractStore.contractEvents.filter((event) => {
      return isDateInsideRange([event.start_date, event.end_date], date)
      return (
        moment(event.start_date, DATE_TIME_FORMAT).format(DATE_FORMAT) ==
        date.format(DATE_FORMAT)
      )
    })
  }

  dateCellRender = (date) => {
    if (!contractStore.contractEvents) return <CalendarEventLoader />
    const events = this.getEventsOrNot(date)
    if (!events) return <></>
    const cell = (
      <div
        title="" // Disable default broswer's behaivor of showing the date in tooltip
        style={{
          margin: isScreenLGAndBigger() ? "8px" : "0px",
          textAlign: "left",
          minWidth: isScreenLGAndBigger() ? "auto" : "100%",
        }}
      >
        <div
          style={{
            padding: "10px",
            minHeight: isScreenLGAndBigger() ? "180px" : "200px",
            maxHeight: isScreenLGAndBigger() ? "auto" : "200px",
            border: !isToday(date)
              ? "1.5px solid " + primaryColor
              : "1.5px solid " + primaryColor,
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            boxShadow: isToday(date) && "2px 4px 8px 3px " + primaryColor,
          }}
        >
          <div
            style={{
              marginBottom: "2px",
              fontSize: "21px",
              fontWeight: "bolder",
              textAlign: "center",
              color: "black",
            }}
          >
            <span
              style={{
                borderRadius: 42,
                padding: 7,
                color: isToday(date) && "white",
                backgroundColor: isToday(date) && addAlpha("#000000", 0.7),
              }}
            >
              {moment(date).format("D")}
            </span>
          </div>

          {this.renderEvents(events, date)}
        </div>
      </div>
    )

    return cell
  }

  renderEvents = (events, date) => {
    return events?.map((event, index) => {
      return this.renderEvent(event)
    })
  }

  render() {
    return (
      <>
        {this.state.displayEvent ? (
          <EditContractModal
            contractId={this.state.displayEvent.id}
            open={this.state.displayEvent}
            close={() =>
              this.setState({
                displayEvent: null,
              })
            }
          />
        ) : (
          <></>
        )}
        <Row>
          <Col span={24}>
            <Calendar
              locale={{
                lang: {
                  locale: "el",
                },
              }}
              contractEvents={contractStore.contractEvents} // Mock mobx reactiveness
              dateFullCellRender={this.dateCellRender}
            />
          </Col>
        </Row>
      </>
    )
  }
}

const styles = {
  titleWrapper: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: isScreenLGAndBigger() ? "nowrap" : "wrap",
  },
}

export default observer(HomePage)
