import { Col, Image, Menu, Row, Divider } from "antd"
import { Content } from "antd/es/layout/layout"

import React from "react"

import {
  LogoutOutlined,
  BankOutlined,
  UserOutlined,
  QuestionCircleOutlined,
  TeamOutlined,
  AppstoreOutlined,
  CarOutlined,
  SnippetsOutlined,
  HomeOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons"
import { NavLink } from "react-router-dom"
import {
  lowerCaseAllWordsExceptFirstLetters,
  mapLocationToMenuKey,
} from "../utils/utils"
import { primaryColor } from "../colors"
import moment from "moment"
import { LOGO, PLATFORM_NAME } from "../config"
import userStore from "../stores/userStore"
const { SubMenu } = Menu

class MainMenu extends React.Component {
  constructor(props) {
    super(props)
  }

  onManualPressed = () => {
    console.log("To be implemented when we have user's manual.")
  }

  onLogoutPressed = () => {
    userStore.logout()
  }

  headerItems = () => {
    const logoutItem = {
      danger: true,
      key: "logout",
      label: "Αποσύνδεση",
      icon: <LogoutOutlined />,
      onClick: () => this.onLogoutPressed(),
    }

    const homeItem = {
      key: "",
      label: <NavLink to="/">Αρχική</NavLink>,
      icon: <HomeOutlined />,
    }

    const carItem = {
      key: "cars",
      label: <NavLink to="/cars">Αυτοκίνητα</NavLink>,
      icon: <CarOutlined />,
    }

    const contractItem = {
      key: "contract-menu",
      label: "Συμβόλαια",
      icon: <SnippetsOutlined />,
      children: [
        {
          key: "contracts",
          label: <NavLink to="/contracts">Συμβόλαια</NavLink>,
          icon: <SnippetsOutlined />,
        },
        {
          key: "create-contract",
          label: <NavLink to="/create-contract">Δημιουργία συμβολαίου</NavLink>,
          icon: <PlusCircleOutlined />,
        },
      ],
    }

    return [homeItem, carItem, contractItem, logoutItem]
  }
  render() {
    return (
      <>
        <Row
          style={{
            top: 0,
            zIndex: 1000,
            display: "flex",
          }}
        >
          <Col span={4} style={{ height: "inherit", display: "flex" }}>
            <Menu
              // theme="dark"
              style={{
                borderRadius: 0,
                flexGrow: 1,
                minHeight: "100vh",
              }}
              breakpoint="lg"
              selectedKeys={mapLocationToMenuKey()}
              mode="inline"
              defaultOpenKeys={["admin-menu", "contract-menu"]}
            >
              <Image
                style={{
                  alignSelf: "center",
                  marginBottom: 20,
                  marginTop: 20,
                  marginLeft: "10%",
                  paddingRight: "40px",
                  //   ...LOGO_STYLES,
                }}
                // width={"220"}
                src={LOGO}
                preview={false}
              />
              {/* <div
                style={{
                  alignSelf: "center",
                  marginBottom: 20,
                  marginTop: 20,
                  // marginLeft: "10%",
                }}
              >
                {PLATFORM_NAME}
              </div> */}
              {/* 
              <Row
                style={{
                  marginLeft: "10%",
                }}
              >
                <Col span={2}>
                  <UserOutlined
                    style={{
                      marginTop: "45%",
                      fontSize: "22px",
                    }}
                  />
                </Col>
                <Col lg={22} md={22} sm={24} xs={24} span={22}>
                  <Row>
                    <Col span={24}>
                      <p style={styles.userDetailsStyle}>
                        {lowerCaseAllWordsExceptFirstLetters(
                          `${userStore.firstName} ${userStore.lastName}`
                        )}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row> */}

              {this.headerItems().map((item) =>
                item.children ? (
                  <SubMenu key={item.key} icon={item.icon} title={item.label}>
                    {item.children.map((child) => (
                      <Menu.Item
                        icon={child.icon}
                        key={child.key}
                        onClick={child.onClick}
                        danger={item.danger}
                      >
                        {child.label}
                      </Menu.Item>
                    ))}
                  </SubMenu>
                ) : (
                  <Menu.Item
                    key={item.key}
                    icon={item.icon}
                    onClick={item.onClick}
                    danger={item.danger}
                  >
                    {item.label}
                  </Menu.Item>
                )
              )}
              <div
                style={{
                  marginBottom: "8px",
                  color: "white",
                  position: "absolute",
                  bottom: 16,
                  marginLeft: "10%",
                }}
              >
                {/* <a target="_blank" rel="noreferrer" href={COMPANY_SITE}>
                  {COMPANY_FULL_NAME}
                </a> */}
              </div>
            </Menu>
          </Col>
          <Col span={20} style={{ display: "flex" }}>
            <Content style={{ margin: "2%", flexGrow: 1, minHeight: "80vh" }}>
              {this.props.children}
              <Divider
                style={{ marginTop: "15vh", borderColor: "transparent" }}
              />
            </Content>

            <div
              style={{
                position: "absolute",
                bottom: 0,
                background: "whitesmoke",
                width: "100%",
                height: "50px",
                borderRadius: "4px",
              }}
            >
              <p style={{ margin: "1.2%" }}>
                <a>
                  © Copyright{" "}
                  <a href="https://ikaria-kampos-cars.gr">Ikaria Kampos Cars</a>{" "}
                  {moment().year()}
                </a>
              </p>
            </div>
          </Col>
        </Row>
      </>
    )
  }
}

const styles = {
  userDetailsStyle: {
    color: "black",
    marginLeft: "5%",
  },
  logoStyle: {
    color: primaryColor,
    marginLeft: "3%",
    marginTop: "1.5%",
  },
}

export default MainMenu
