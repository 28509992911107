import { observable, action, makeObservable } from "mobx"
import { unauthorizedApi } from "../apis/api"

class UserStore {
  firstName = localStorage.getItem("firstName")
  lastName = localStorage.getItem("lastName")
  token = localStorage.getItem("token")
  username = localStorage.getItem("username")
  email = ""
  loginTimestamp = null

  constructor() {
    makeObservable(this, {
      firstName: observable,
      lastName: observable,
      token: observable,
      username: observable,
      email: observable,
      loginTimestamp: observable,
      login: action,
      logout: action,
    })
  }

  login = async (username, password) => {
    unauthorizedApi
      .post("login", { username, password })
      .then((response) => {
        localStorage.setItem("token", response.data.token)
        localStorage.setItem("firstName", response.data.first_name)
        localStorage.setItem("lastName", response.data.last_name)
        localStorage.setItem("username", response.data.username)

        this.firstName = response.data.first_name
        this.lastName = response.data.last_name
        this.token = response.data.token
        this.username = response.data.username
        this.loginTimestamp = new Date()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  logout = () => {
    this.firstName = ""
    this.lastName = ""
    this.token = ""
    this.username = ""
    this.email = ""
    this.loginTimestamp = null
    localStorage.clear()
  }
}

const userStore = new UserStore()
export default userStore
